<template>
  <div class="sign-wrapper">
    <div class="sign" v-if="!isMailSent" >
      <div class="sign-up">
        <router-link class="sign-up__link" :to="{ name: 'sign-up' }">
          Don’t have an account? Sign up.
        </router-link>
        <a class="sign-up__google" href="#">
          <span class="sign-up__google-icon">
<!--            <img src="@/assets/images/google.svg" alt="google">-->
          </span>
          <!--          Login with Google-->
        </a>
      </div>
      <h1 class="sign__title">Forgot Password?</h1>

      <form class="sign__form" @submit.prevent="sendEmail">

        <input v-model="email" class="sign__input" type="text" placeholder="Email"/>

        <small v-if="message" class="d-block text-center mt-3 font-weight-light">{{ message }}</small>

        <div v-if="$v.email.$error">
          <small v-if="!$v.email.required" class="sign__form-error sign__form-error_tip">
            This field is required
          </small>
          <small v-if="$v.email.$invalid" class="sign__form-error sign__form-error_tip">
            Invalid email address
          </small>
        </div>

        <div class="entry-actions-wrapper sign__submit-wrapper">
          <button v-if="!loading" class="sign__submit">
            Reset password
          </button>

          <b-spinner v-else variant="primary"></b-spinner>
        </div>

      </form>

      <p class="sign__forgot-links">
        <router-link class="entry-link" :to="{ name: 'sign-up' }">Sign up</router-link>
        or
        <router-link class="entry-link" :to="{ name: 'sign-in' }">Login</router-link>
      </p>

    </div>
    <div v-else>
      <div class="verification__icon">
        <img src="@/assets/images/verification-email.svg" alt="email">
      </div>
      <h1 class="verification__title">
        Please check your mailbox for the reset password link.
      </h1>
      <p>
        <router-link class="verification__link" :to="{ name: 'home' }">
          Go to Main
        </router-link>
      </p>
    </div>

  </div>

</template>

<script>
  import { FORGOT_PASSWORD, MODULE_NAME as AUTH } from '@/store/modules/auth'
  import { email, required } from 'vuelidate/lib/validators'

  export default {
    name: 'ForgotPassword',
    components: {
    },
    data() {
      return {
        email: '',
        isMailSent: false,
        message: ''
      }
    },
    computed: {
      loading() {
        return this.$store.state[AUTH].forgotPassword.loading
      }
    },
    validations: {
      email: {
        required,
        email
      }
    },
    methods: {
      sendEmail() {
        if (this.loading) {
          return
        }

        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }

        this.$store.dispatch(FORGOT_PASSWORD, { email: this.email }).then(() => {
          this.isMailSent = true
        })
      }
    }
  }
</script>
